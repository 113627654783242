import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueNumberFormat from '@coders-tm/vue-number-format'

Vue.use(VueNumberFormat, { precision: 4 })


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
