<template>
  <div class="page">
    <Hero />
  </div>
</template>

<script>
  import Hero from '@/components/Hero'
  export default {
    name: "Main",
    components: {
      Hero,
    }
  }
</script>