<template>
	<div class="hero">
		<transition name="fade">
			<div class="banner" v-if="isCredit == true">
				<div class="banner__wrapper">
					<div class="banner__top">
						<h1>Сколько вы заработаете за полгода, если откроете <br>вклад прямо сейчас?</h1>
					</div>
					<div class="banner__content">
						<div class="banner__content-title">Укажите вашу сумму вклада<br>от 15 000 ₽ до 1 000 000 ₽:</div>
						<input type="text" placeholder="0 ₽" class="banner__content-input" v-number="number" v-model="deposit" :class="{'error': error, invalid: invalid}" @input="invalid = false">
						<a href="javascript:void(0)" class="banner__content-btn" @click="calculate">Рассчитать</a>
					</div>
					<div class="banner__bottom">
						<div class="banner__bottom-logo"><img src="../assets/img/logo.svg" alt=""></div>
						<div class="banner__bottom-text"><br>Реклама. Рекламодатель — ПАО Росбанк.</div>
					</div>
				</div>
			</div>
		</transition>

		<transition name="fade">
			<div class="banner" v-show="isSum == true">
				<div class="banner__wrapper">
					<div class="banner__top">
						<h1>Дарим ставку 15% годовых по вкладу «Надёжный»</h1>
					</div>
					<div class="banner__content banner__content--sum">
						<div class="banner__content-title">Ваш доход составит:</div>
						<div class="banner__content-sum">{{result}}<span>₽</span></div>
						<a href="https://www.rosbank.ru/" target="_blank" class="banner__content-btn banner__content-btn--open">Открыть вклад</a>
					</div>
					<div class="banner__bottom banner__bottom--sum">
						<div class="banner__bottom-logo"><img src="../assets/img/logo.svg" alt=""></div>
						<div class="banner__bottom-text"><br>Реклама. Рекламодатель — ПАО Росбанк.</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	name: "Hero",
	data() {
		return {
			isSum: false,
			isCredit: true,
			deposit: '',
			error: false,
			invalid: false,
			result: null,
			number: {
				decimal: '.',
				separator: ' ',
				suffix: ' ₽',
				precision: 0,
				masked: true,
			},
		}
	},
	methods: {
		formatNumber(x) {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
		},
		calculate() {
			let depo = this.deposit.match(/\d/g);
			let deposit = depo.join("");
			if (parseInt(deposit) < 15000 || parseInt(deposit) > 1000000) {
				this.error = true;
				this.invalid = true;
				setTimeout(() => {
					this.error = false;
				}, 500)
				return false;
			}
			this.result = (182*deposit/100*15)/366;
			this.result = this.result.toFixed(2);
			this.result = this.formatNumber(this.result)
			this.isSum = true;
			this.isCredit = false;
		}
	}
}
</script>

<style lang="sass">
*
	box-sizing: border-box
.hero
	background-color: #fff
.banner
	width: 100%
	height: 400px
	background: #00234D
	padding: 0px 48px 23px 42px
	font-family: 'Montserrat'
	font-style: normal
	position: absolute
	@media screen and (max-width: 1850px)
		padding: 0px 43px 23px 36px
	@media screen and (max-width: 1150px)
		padding: 0px 29px 23px 23px
	@media screen and (max-width: 599px)
		padding: 0px 27px 17px 19px
	@media screen and (max-width: 390px)
		padding: 0px 30px 17px 19px
	&__top
		padding-top: 48px
		@media screen and (max-width: 1850px)
			padding-top: 44px
		@media screen and (max-width: 1150px)
			padding-top: 46px
		@media screen and (max-width: 599px)
			padding-top: 32px
		h1
			color: #FFF
			font-size: 40px
			font-weight: 800
			line-height: 52px
			@media screen and (max-width: 1900px)
				font-size: 35px
				line-height: 45.5px
			@media screen and (max-width: 1850px)
				font-size: 35px
				line-height: 45.5px
			@media screen and (max-width: 1250px)
				font-size: 30px
				line-height: 40.5px
			@media screen and (max-width: 1150px)
				font-size: 20px
				line-height: 26px
			@media screen and (max-width: 599px)
				font-size: 20px
				line-height: 23.4px
			span
				@media screen and (max-width: 1500px)
					display: block
			br
				display: none
				@media screen and (min-width: 860px)
					display: block
	&__wrapper
		display: table
		@media screen and (min-width: 1921px)
			margin: auto
	&__content
		display: flex
		margin-top: 40px
		align-items: center
		@media screen and (max-width: 1850px)
			margin-top: 40px
		@media screen and (max-width: 1150px)
			margin-top: 15px
			flex-wrap: wrap
			flex-direction: row
		@media screen and (max-width: 599px)
			margin-top: 28px
		&--sum
			margin-top: 40px
			@media screen and (max-width: 1850px)
				margin-top: 54px
			@media screen and (max-width: 599px)
				margin-top: 28px
		&-title
			color: #FFF
			font-size: 30px
			font-weight: 400
			line-height: 45px
			opacity: 0.9
			@media screen and (max-width: 1850px)
				font-size: 25px
				line-height: 37.5px
			@media screen and (max-width: 1250px)
				font-size: 20px
				line-height: 30.5px
			@media screen and (max-width: 1150px)
				font-size: 18px
				line-height: 27px
				width: 100%
			@media screen and (max-width: 599px)
				font-size: 12px
				line-height: 18px
		&-input
			margin-left: 105px
			width: 656px
			height: 60px
			border-radius: 4px
			background: #FFF
			outline-style: none
			border: none
			color: #000
			font-size: 30px
			font-weight: 500
			line-height: normal
			padding-left: 36px
			&.invalid
				color: #E40038
			&.error
				animation: shake
				animation-duration: 300ms
				animation-iteration-count: 1
			&::placeholder
				color: #C4C4C4
				font-size: 30px
				font-weight: 400
				line-height: normal
				opacity: 0.9
				@media screen and (max-width: 1850px)
					font-size: 25px
				@media screen and (max-width: 1150px)
					font-size: 18px
				@media screen and (max-width: 599px)
					font-size: 14px
			@media screen and (max-width: 1850px)
				font-size: 25px
				width: 356px
				margin-left: 70px
				padding-left: 28px
			@media screen and (max-width: 1150px)
				margin-left: 0
				margin-top: 18px
				padding-left: 14px
				width: 320px
				font-size: 18px
			@media screen and (max-width: 599px)
				margin-top: 18px
				font-size: 14px
				height: 42px
				width: 365px
				padding-left: 20px
			@media screen and (max-width: 390px)
				margin-top: 18px
				font-size: 14px
				height: 42px
				width: 268px
				padding-left: 18px

		&-sum
			color: #FFF
			font-size: 100px
			font-weight: 600
			line-height: 90px
			opacity: 0.9
			margin-left: 125px
			border-bottom: 5px solid rgba(228, 0, 56, 1)
			span
				font-size: 50px
				@media screen and (max-width: 1150px)
					font-size: 30px
			@media screen and (max-width: 1850px)
				font-size: 100px
				margin-left: 60px
			@media screen and (max-width: 1200px)
				font-size: 90px
			@media screen and (max-width: 1150px)
				font-size: 58.772px
				line-height: 55px
				margin-top: 29px
				margin-left: 0
				border-bottom: 3px solid rgba(228, 0, 56, 1)
			@media screen and (max-width: 599px)
				border-bottom: 2px solid rgba(228, 0, 56, 1)
				font-size: 45px
				line-height: 40px
				margin-top: 18px
				margin-right: 200px
			@media screen and (max-width: 470px)
				margin-right: 50px
			@media screen and (max-width: 390px)
				margin-right: 0

		&-btn
			display: flex
			align-items: center
			width: 304px
			height: 60px
			border-radius: 50px
			border: 2.325px solid #E40038
			background: #E40038
			color: rgba(255, 255, 255, 0.9)
			justify-content: center
			font-size: 20px
			font-weight: 500
			line-height: 30px
			margin-left: 65px
			text-decoration: none
			transition: all 300ms
			&:hover
				font-weight: 800
			&--open
				margin-left: 115px
				@media screen and (max-width: 1850px)
					margin-left: 80px !important
				@media screen and (max-width: 1150px)
					margin-top: 25px
					width: 193px
					margin-left: 40px !important
				@media screen and (max-width: 599px)
					margin-left: 0px !important
					width: 193px
					height: 40px
					margin-top: 25px !important
					
			@media screen and (max-width: 1850px)
				width: 300px
				margin-left: 57px
				height: 56px
			@media screen and (max-width: 1150px)
				margin-top: 18px
				width: 193px
				margin-left: 21px
				font-size: 16px
			@media screen and (max-width: 599px)
				margin-top: 16px
				margin-left: 0px
				width: 193px
				height: 36px 
				font-size: 14px
				line-height: 21px
	&__bottom
		display: flex
		margin-top: 40px
		align-items: center
		@media screen and (max-width: 1150px)
			display: block
			margin-top: 32px !important
		@media screen and (max-width: 599px)
			margin-top: 20px !important
		@media screen and (max-width: 390px)
			margin-top: 25px !important
		&--sum
			margin-top: 65px
			@media screen and (max-width: 1850px)
				margin-top: 55px !important
			@media screen and (max-width: 1150px)
				margin-top: 65px !important
			@media screen and (max-width: 599px)
				margin-top: 50px !important
			@media screen and (max-width: 390px)
				margin-top: 50px !important
		&-logo
			img
				width: 470px
				height: 52px
				@media screen and (max-width: 1850px)
					width: 435px
					height: 48px
				@media screen and (max-width: 1250px)
					width: 326px
					height: 36px
				@media screen and (max-width: 1150px)
					width: 326px
					height: 36px
				@media screen and (max-width: 599px)
					width: 349px
					height: 37px
				@media screen and (max-width: 390px)
					width: 264px
					height: 28px
		&-text
			margin-left: 40px
			color: #FFF
			font-size: 12px
			font-weight: 400
			opacity: 0.9
			width: 1020px
			@media screen and (max-width: 1850px)
				width: 727px
			@media screen and (max-width: 1150px)
				margin-left: 0
				margin-top: 0px
				font-size: 12px
				width: 548px
			@media screen and (max-width: 599px)
				font-size: 12px
				width: 289px
				

.fade-enter-active, .fade-leave-active
	transition: opacity .5s
.fade-enter, .fade-leave-to
	opacity: 0
</style>

<style>
@keyframes shake {
 0% { transform: translateX(0) }
 25% { transform: translateX(5px) }
 50% { transform: translateX(-5px) }
 75% { transform: translateX(5px) }
 100% { transform: translateX(0) }
}
</style>